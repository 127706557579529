import { container } from "assets/jss/nextjs-material-kit.js";

const carouselStyle = {
  section: {
    padding: "10px 0"
  },
  container,
  marginAuto: {
    // marginLeft: "auto !important",
    // marginRight: "auto !important"
  },
  image : {
    width: '100%'
  }
};

export default carouselStyle;
