import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";

import styles from "assets/jss/nextjs-material-kit/pages/componentsSections/tabsStyle.js";

const UseStyles = makeStyles(styles);

function createMarkup(content) {
  return {__html: content};
}

const timetableItems = [
  {
  //   month: 'listopad',
  //   content: `
  //     <p>08-10.11.2019 piątek-sobota-niedziela JeReO - Góra św. Anny</p>
  //     <p>23.11.2019 sobota Szkoła Animatora - Spotkanie 2</p>`,
  // },{
  //   month: 'grudzień',
  //   content: `<p>09.12.2019 poniedziałek Zespół Pastoralny 2 - Opole</p>`,
  // },{
  //   month: 'styczeń',
  //   content: `<p>25.01.2020 sobota Szkoła Animatora - Spotkanie 3</p>`,
  // },{
  //   month: 'luty',
  //   content: `
  //     <p>29.02.2020 sobota Szkoła Animatora - Spotkanie 4</p>
  //     <p>28-29.02.2020 piątek-sobota Spotkanie KZK Magdalenka</p>`,
  // },{
    month: 'marzec',
    content: `
      <p>09.03.2020 poniedziałek Zespół Pastoralny 3 - Opole</p>
      <p>28.03.2020 sobota Szkoła Animatora - Spotkanie 5</p>`,
  },{
    month: 'kwiecień',
    content: `<p>24-26.04.2020 piątek-sobota-niedziela Rekolekcje dla Wstawienników</p>`,
  },{
    month: 'maj',
    content: `
      <p>04.05.2020 poniedziałek Zespół Pastoralny 4 - Opole</p>
      <p>14-16.05.2020 czwartek-piątek-sobota Kongres Odnowy w Częstochowie</p>
      <p>30.05.2020 sobota Czuwanie przed Uroczystością Zesłania Ducha Świętego</p>`,
  },{
    month: 'czerwiec',
    content: `
      <p>11.06.2020 czwartek Koncert Uwielbienia </p>
      <p>??? piątek-sobota Spotkanie KZK Magdalenka</p>`,
  }
];

export default function SectionTabsTimetable() {
  const classes = UseStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <div id="nav-tabs">
          <h3>Terminarz Diecezjalny 2019/2020</h3>
          <GridContainer>
            <GridItem xs={12} >
              <CustomTabs
                headerColor="primary"
                tabs={timetableItems.map(timetableItem => (
                      {
                        tabName: timetableItem.month,
                        tabContent: (
                          <div dangerouslySetInnerHTML={createMarkup(timetableItem.content)} />
                        )
                      }
                    )
                  )
                }
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
