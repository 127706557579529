import React from "react";
import Layout from 'layouts/layout'

// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/nextjs-material-kit/pages/landingPage.js";

// Sections for this page
import SectionGeneralCarousel from "pages-sections/Components-Sections/SectionGeneralCarousel.js";
import SectionTabsTimetable from "pages-sections/Components-Sections/SectionTabsTimetable.js";
import GoldSection from "pages-sections/Components-Sections/SectionGold.js";
import SectionCarousel from "pages-sections/Components-Sections/SectionCarousel.js";

// import Countdown from 'react-countdown-now';

const UseStyles = makeStyles(styles);

export default function index(props) {
  const classes = UseStyles();
  // const hour = 1000 * 60 * 60
  // const current = Date.now();
  // const deadlLine = Date.parse('01 Oct 2019 00:00:00 UTC')
  // const left = deadlLine - current;
  return (
    <Layout>
      <div className={classNames(classes.main, classes.mainRaised)}>
        {/* <div className={classes.container} style={{ display: 'flex' }}>
          <div className={classes.section}>
            <div className={classes.container}>
              <div id="typography">
                <div style={{ color: 'black', padding: '50px', margin: '10vw auto' }} >
                  <p style={{ fontSize: '1.5rem' }}>
                    Trwają prace konserwatorskie, prosimy o cierpliwość
                    <hr />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      {/* </div> */}
      <SectionGeneralCarousel/>
          <div className={classes.container}>
          </div>
          <SectionTabsTimetable />
          <GoldSection />
          <div className={classes.container}>
            <SectionCarousel/>
          </div>
      </div>
    </Layout>
  );
}
