import React from "react";
import { Link } from "gatsby";
// nodejs library that concatenates classes
import classNames from "classnames";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";

import styles from "assets/jss/nextjs-material-kit/pages/componentsSections/carouselStyle.js";

const UseStyles = makeStyles(styles);

export default function SectionCarousel() {
  const items = [
    {
      src: "/communitiesSlides/cantate-deo/prudnik-wieczor-uwielbiebia-03-2020.jpg",
      alt: "prudnik wieczor uwielbienia 20-03-2020",
      href: "prudnik-wieczor-uwielbiebia-03-2020",
      internal: true
    // },{
    //   src: "static/communitiesSlides/magnificat/raciborski-wieczor-uwielbienia-07-03-2020.jpg",
    //   alt: "magnificat alpha 2020",
    //   href: "raciborski-wieczor-uwielbienia-07-03-2020",
    //   internal: true
    // },{
    //   src: "/communitiesSlides/magnificat/magnificat-aplha-2020.jpg",
    //   alt: "magnificat alpha 2020",
    //   href: "magnificat-aplha-2020",
    //   internal: true
    // },{
    //   src: "/communitiesSlides/magnificat/magnificat-pulikowski-2020.jpg",
    //   alt: "magnificat alpha 2020",
    //   href: "magnificat-pulikowski-2020",
    //   internal: true
    // },
    // {
    //   src: "/communitiesSlides/effatha/effatha-wieczor-uwielbienia-2020-02-24.jpg",
    //   alt: "effatha wieczor uwielbienia 2020-02-24",
    //   href: "/effatha-wieczor-uwielbienia-2020-02-24",
    //   internal: true
    // },{
    //   src: "/communitiesSlides/emaus/emaus-rekolekcje-odnowy-wiary-2020-zajawka.jpg",
    //   alt: "wspólnota emaus rekolekcje odnowy wiary",
    //   href: "/emaus-emaus-rekolekcje-odnowy-wiary-2020",
    //   internal: true
    // },{

    //   src: '/communitiesSlides/szkola-maryi-w-opolu-winowie.jpg',
    //   alt: 'szkoła maryii w opolu-winowie',
    //   href: 'https://www.facebook.com/events/s/szko%C5%82a-maryi-w-opolu-winowie/2558217950942045/?ti=cl',
    //   internal: false
    // },{
    //   src: '/generalSlides/wieczor-uwielbienia-19-01-2020.jpg',
    //   alt: 'zaproszenie Wieczór uwielbienia 19 01 2020',
    //   href: '/wieczor-uwielbienia-19-01-2020',
    //   internal: true
    // },{
    //   src: '/communitiesSlides/aplikacja-2.jpg',
    //   alt: 'Aplikacja Pismo Święte',
    //   href: '/aplikacja-pismo-swiete',
    //   internal: true
    }
  ];
  const classes = UseStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
  };
  return (
    <div className={classes.section} id="carousel2">
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={12} className={classes.marginAuto}>
            <Card carousel>
              <Carousel {...settings}>
                { items.map((item, idx) => (
                  <Link to={item.href} key={`${item.href}-${idx}`}>
                    <img src={item.src} alt={item.alt} className={classNames("slick-image", classes.image)} />
                  </Link>
                ))}
              </Carousel>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
