import React from "react";
import { Link } from "gatsby";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/nextjs-material-kit/pages/componentsSections/loginStyle.js";
// import beyond from ;

const UseStyles = makeStyles(styles);

const communityNews = [
  {
    communityName: 'wspólnoty',
    img: '/wspolnota.jpg',
    title: '',
    url: '/wspolnoty',
    next: 'czytaj więcej'
  },{
    communityName: 'wydarzenia',
    img: '/flowers.jpg',
    title: '',
    url: '/wydarzenia',
    next: 'czytaj więcej'
  },
  {
    communityName: 'szkoła animatora',
    img: '/goldSection/szkola-animatora.jpg',
    title: '',
    url: '/szkola-animatora-2019',
    next: 'czytaj więcej'
  },  
  {
    communityName: 'dom miłosierdzia',
    img: '/comminitiesLogos/dom-milosierdzia.jpg',
    title: '',
    url: 'http://dommilosierdziaotmuchow.pl/',
    external: true,
    next: 'czytaj więcej'
  }
]

export default function GoldSection() {
  const classes = UseStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justify="center">
          {communityNews.map((news, index) => 
            <GridItem xs={12} sm={6} md={3} key={index}>
              <Card style={{height: '100%'}}>
                <CardHeader color="primary" className={classes.cardHeader}>
                  <h4>{news.communityName}</h4>
                </CardHeader>
                <CardBody style={{
                  display: 'flex',
                  flexFlow: 'column',
                  justifyContent: 'center',}}>
                  <img
                    src={news.img}
                    alt="..."
                    className={
                      classes.imgRaised +
                      " " +
                      classes.imgRounded +
                      " " +
                      classes.imgFluid
                    }
                    style={{maxWidth: '100%'}}
                  />
                  <h5 style={{textAlign: 'center'}}>{news.title}</h5>
                  <h5 style={{textAlign: 'center'}}>{news.date} </h5>
                </CardBody>
                <CardFooter className={classes.cardFooter}>
                  { news.next && news.external ? 
                  <a href={news.url}>
                    <Button round color="warning" >
                      {news.next}
                    </Button>
                  </a> : 
                  <Link to={news.url}>
                    <Button round color="warning" >
                      {news.next}
                    </Button>
                  </Link>}
                </CardFooter>
              </Card>
            </GridItem>
          )}
        </GridContainer>
      </div>
    </div>
  );
}
