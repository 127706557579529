import React from "react";
import { Link } from "gatsby";
// nodejs library that concatenates classes
import classNames from "classnames";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";

// import image1 from "";

import styles from "assets/jss/nextjs-material-kit/pages/componentsSections/carouselStyle.js";

const UseStyles = makeStyles(styles);

export default function SectionGeneralCarousel() {
  const classes = UseStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
  };
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer id="#carousel">
          <GridItem xs={12} className={classes.marginAuto}>
            <Card carousel>
              <Carousel {...settings}>
                 <Link to="/szkola-animatora-5-2019-2020">
                 <img src={'/animator-5/szkola_animatora_5.jpg'} alt="Szkoła animatora spotaknie 5 - odwołane" className={classNames("slick-image", classes.image)} />
                </Link>
              </Carousel>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
